(function ($) {
  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.

  // setInterval(function () {
  //   console.log(dirty);
  // }, 1000);

  var Sage = {
    'application_form': {
      init: function () {

        //var rec_count = 1;
        // if (!sessionStorage.getItem('rec_count'))
        //   sessionStorage.setItem('rec_count', 1);
        //sessionStorage.clear();
        var validator = {
          debug: true,
          errorClass: 'error',
          ignore: "input[type='file']",
          // rules: {
          //   surname: {
          //     required: true
          //   }
          // },
          // invalidHandler: function (event, validator) {
          //   // 'this' refers to the form
          //   var errors = validator.numberOfInvalids();
          //   if (errors) {
          //     // var message = errors == 1
          //     //   ? 'You missed 1 field. It has been highlighted'
          //     //   : 'You missed ' + errors + ' fields. They have been highlighted';
          //     // $("div.error span").html(message);
          //     // $("div.error").show();
          //     console.log(errors);
          //   }
          // },
          // showErrors: function (errorMap, errorList) {
          //   var errors = this.numberOfInvalids(); // <- NUMBER OF INVALIDS
          //   $("#num_invalids").html(errors);

          //   console.log(errorMap);
          //   $.each(errorMap, function (key, value) {
          //     console.log(key); // <- name of invalid field
          //     var parent = $('[name="' + key + '"]').parent();
          //     console.log(parent); // <- parent object
          //   });

          //   this.defaultShowErrors(); // <- ENABLE default MESSAGES
          // }
        };
        $('#appFrm').validate(validator);
        $('#appFrm').on('click', '.next1', function () {
          var fields = $('#personal-details').find(":input");
          //console.log($('#appFrm').valid());
          //if ($('#appFrm').valid()) {
          //console.log('click');
          if (fields.valid()) {
            $('#app-frm-tabs #tab2').removeClass('disabled');
            $('#app-frm-tabs #tab2 a').attr('data-toggle', 'tab');
            $('#app-frm-tabs #tab2 a').tab('show');
            $("html, body").animate({ scrollTop: 0 }, "slow");
          }
        });
        $('#appFrm').on('click', '.prev2', function () {
          $('#app-frm-tabs #tab1 a').tab('show');
          $("html, body").animate({ scrollTop: 0 }, "slow");
        });
        $('#appFrm').on('click', '.next2', function () {
          //console.log('next2');
          var fields = $('#employment').find(":input");
          if (fields.valid()) {
            $('#app-frm-tabs #tab3').removeClass('disabled');
            $('#app-frm-tabs #tab3 a').attr('data-toggle', 'tab');
            $('#app-frm-tabs #tab3 a').tab('show');
            $("html, body").animate({ scrollTop: 0 }, "slow");
          }
        });

        $('#appFrm').on('click', '.prev3', function () {
          $('#app-frm-tabs #tab2 a').tab('show');
          $("html, body").animate({ scrollTop: 0 }, "slow");
        });

        $('#appFrm').on('click', '.next3', function () {
          //console.log('next3');
          var fields = $('#education').find(":input");
          if (fields.valid()) {
            $('#app-frm-tabs #tab4').removeClass('disabled');
            $('#app-frm-tabs #tab4 a').attr('data-toggle', 'tab');
            $('#app-frm-tabs #tab4 a').tab('show');
            $("html, body").animate({ scrollTop: 0 }, "slow");
          }
        });

        $('#appFrm').on('click', '.prev4', function () {
          $('#app-frm-tabs #tab3 a').tab('show');
          $("html, body").animate({ scrollTop: 0 }, "slow");
        });

        $('#appFrm .input-group.date').datepicker({
          format: "dd/mm/yyyy",
          autoclose: true
        });

        var rec_count = sessionStorage.getItem('rec_count');
        var qual_count = sessionStorage.getItem('qual_count');
        //console.log(rec_count);

        var counter = 1;
        var counter2 = 2;
        //####################################
        var get_application_employer_html = function (recCount, increment) {
          var data = {
            action: 'application_employer_html',
            rec_count: recCount,
            total_prevs: rec_count
          };
          console.log(data);
          var jqxhr = $.post('/wp-admin/admin-ajax.php', data, function (resp) {
              //console.log(rec_count, resp);
              if (resp.resp == 'OK') {
                //console.log(increment);

                if (increment) {
                  //rec_count++;
                  $('#appFrm [class^=rem_prev_wrap-]').remove();
                  sessionStorage.setItem('rec_count', rec_count);
                } else if (counter < rec_count) {
                  counter++;
                  get_application_employer_html(counter, false);
                }

                $('#employers-wrap').append(resp.HTML);
                //setTimeout(function () {
                //console.log($('#appFrm #employ-' + recCount + ' .input-group.date'));
                $('#appFrm #employ-' + recCount + ' .input-group.date').datepicker({
                  format: "dd/mm/yyyy",
                  autoclose: true
                });
                //console.log('adding_date-picker ' + recCount);
                FormPersistence.persist(document.getElementById('appFrm'), {
                  // true = use local storage instead
                  useSessionStorage: true,
                  //saveOnSubmit: true
                });

                //}, 200);
                //console.log(rec_count);
              }
            }, "json")
            .fail(function () {
              ajax_fail(jqxhr);
            });
        };

        $('#add-prev-employ').on('click', function () {
          rec_count++;
          sessionStorage.setItem('rec_count', rec_count);
          get_application_employer_html(rec_count, true);
        });

        if (rec_count > 0)
          get_application_employer_html(counter, false);

        //############################################
        $('#appFrm').on('click', '.remove_prev_employ', function () {
          if (confirm('Are you sure you wish to delete this previous employer?')) {
            $('#employ-' + $(this).attr('data-value')).remove();
            rec_count--;
            sessionStorage.setItem('rec_count', rec_count);

            $('#employers-wrap #employ-' + rec_count + ' hr').after('<div id="rem_prev_wrap-' + rec_count + '" class="form-group row"><div class="col-xs-offset-4 col-xs-8"><button style="font-size: 12px;padding: 3px 12px;" data-value="' + rec_count + '" type="button" class="btn btn-sm btn-danger pull-right remove_prev_employ"><i class="fa fa-times"></i> Remove</button></div></div>');

            FormPersistence.clearStorage(document.getElementById('appFrm'), {
              // true = use local storage instead
              useSessionStorage: true,
            });

            // FormPersistence.persist(document.getElementById('appFrm'), {
            //   // true = use local storage instead
            //   useSessionStorage: true,
            // });
            //get_application_employer_html(counter, false);
          }
          //console.log($(this).attr('data-value'));
        });

        //####################################
        var get_application_qualifications = function (qualCount, increment) {
          var data = {
            action: 'application_form_qualifications',
            qual_count: qualCount,
            total_quals: qual_count
          };
          var jqxhr = $.post('/wp-admin/admin-ajax.php', data, function (resp) {
              if (resp.resp == 'OK') {
                if (increment) {
                  $('#qualifications-wrap [class^=rem_prev_qual_wrap-]').remove();
                  sessionStorage.setItem('qual_count', qual_count);
                } else if (counter2 < qual_count) {
                  counter2++;
                  get_application_qualifications(counter2, false);
                }

                $('#qualifications-wrap').append(resp.HTML);
                FormPersistence.persist(document.getElementById('appFrm'), {
                  // true = use local storage instead
                  useSessionStorage: true,
                  saveOnSubmit: true
                });
              }
            }, "json")
            .fail(function () {
              ajax_fail(jqxhr);
            });
        };


        $('#add-qualification').on('click', function () {
          qual_count++;
          sessionStorage.setItem('qual_count', qual_count);
          get_application_qualifications(qual_count, true);
        });

        //############################################
        $('#appFrm').on('click', '.remove_prev_qualification', function () {
          if (confirm('Are you sure you wish to delete this qualification?')) {
            $('#qualifications-' + $(this).attr('data-value')).remove();
            qual_count--;
            sessionStorage.setItem('qual_count', qual_count);

            $('#qualifications-wrap #qualifications-' + qual_count + ' hr').after('<div id="rem_prev_qual_wrap-' + qual_count + '" class="form-group row"><div class="col-xs-offset-4 col-xs-8"><button style="font-size: 12px;padding: 3px 12px;" data-value="' + qual_count + '" type="button" class="btn btn-sm btn-danger pull-right remove_prev_qualification"><i class="fa fa-times"></i> Remove</button></div></div>');

            FormPersistence.clearStorage(document.getElementById('appFrm'), {
              // true = use local storage instead
              useSessionStorage: true,
            });

            // FormPersistence.persist(document.getElementById('appFrm'), {
            //   // true = use local storage instead
            //   useSessionStorage: true,
            // });
            //get_application_employer_html(counter, false);
          }
          //console.log($(this).attr('data-value'));
        });

        if (qual_count > 1)
          get_application_qualifications(counter2, false);

        FormPersistence.persist(document.getElementById('appFrm'), {
          // true = use local storage instead
          useSessionStorage: true,
          //saveOnSubmit: true
        });

        //##############  UPLOADER  ###################
        // UPLOADER PROJECT SUBMISIONS FILES
        var cv_uploader = new plupload.Uploader({
          //autostart: true,
          multi_selection: false,
          runtimes: 'html5,flash,silverlight,html4',
          browse_button: 'cv_btnAdd', // you can pass an id...
          //container: document.getElementById('container'), // ... or DOM Element itself
          url: '/application-form/upload/',
          // resize: {
          //   width: 1500,
          //   height: 1200
          // },
          filters: {
            max_file_size: '20mb',
            mime_types: [{
              title: "CV File",
              extensions: "doc,docx,pdf"
            }]
          },
          // Flash settings
          flash_swf_url: '/wp-content/themes/dextra/dist/scripts/pluploader/Moxie.swf',

          // Silverlight settings
          silverlight_xap_url: '/wp-content/themes/dextra/dist/scripts/pluploader/Moxie.xap',
          //   multipart_params : {
          //    propID : propID
          //   },
          init: {
            PostInit: function () {

            },
            FilesAdded: function (up, files) {
              //var queue = 0;
              var disp_text = 'Uploading: ';
              plupload.each(files, function (file) {
                console.log(file.name);
                disp_text += file.name + ', ';
              });
              $('#cv_file_name').text(disp_text);
              $('#cv_upload .progressbar-df').show();
              //setTimeout(cv_uploader.start(), 1);
            },
            BeforeUpload: function () {

            },
            FileUploaded: function (up, file, ret) {
              console.log(ret);
              var resp = JSON.parse(ret.response);
              if (resp.resp === 'OK') {
                //if ($('input[name="cv_File"]').val() === '')
                $('input[name="cv_file"]').val(resp.file_name);
                // else
                //   $('input[name="dc_projFile"]').val($('input[name="dc_projFile"]').val() + ',' + resp.file_name);
              } else {
                alert("ERROR: Failed to get correct upload response for 'Project Files'");
              }
            },
            UploadComplete: function (up, files) {

            },
            UploadProgress: function (up, file) {
              $('#cvProgBar').width(file.percent + '%');
            },
            Error: function (up, err) {
              alert("Error code (" + err.code + "):\n\n" + err.message);
              console.log("\nError #" + err.code + ": " + err.message + ' ' + err.response);
            }
          }

        });

        cv_uploader.init();

        //###  PROJECT SUBMISSION SAVE ###
        //$('#appfrm_submit').click(function (e) {
        $('#appFrm').on('submit', function (e) {
          var $this = $(this);
          e.preventDefault();
          if ($('#appFrm').valid()) {
            if (cv_uploader.files.length)
              cv_uploader.start();

            $('#appfrm_submit').prop("disabled", true).css({
              'color': '#7D7D7D',
              'cursor': 'default'
            });

            var subInt = setInterval(function () {
              console.log(cv_uploader.total.uploaded + ' === ' + cv_uploader.files.length);
              if (cv_uploader.total.uploaded === cv_uploader.files.length) {
                clearInterval(subInt);
                //console.log('subbin');
                var form = $this.ajaxSubmit({
                  //target: '#myResultsDiv'
                });

                var xhr = form.data('jqxhr');

                xhr.done(function () {
                  setTimeout(function () {
                    //console.log(xhr);
                    location.href = "/application-thank-you/";
                  }, 500);
                });
                //});

              }
            }, 500);
          }
        });

      }

    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function (func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function () {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function (i, classnm) {
        //console.log(classnm);
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);



})(jQuery); // Fully reference jQuery after this point.
